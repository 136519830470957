import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';
import api from '../api';
import './Login.css';

const API = process.env.REACT_APP_API;

async function login(username, password, apiVersion) {
  const baseUrl = API;

  try {
    const data = await api.post(`${baseUrl}/admin/login`, {
      username,
      password
    });

    if (data.token) {
      const hashedApiVersion = CryptoJS.SHA256(apiVersion).toString();
      localStorage.setItem('token', data.token);
      localStorage.setItem('apiVersion', hashedApiVersion);
      if (data.adminId) {
        localStorage.setItem('adminId', data.adminId);
      }
      return data;
    } else {
      throw new Error('Unauthorized');
      }
    } catch (err) {
      console.error('Login error:', err);
      throw err;
    }
  }

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [apiVersion, setApiVersion] = useState('1');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isPower, setIsPower] = useState(false);

  useEffect(() => {
    const url = window.location.href.toLowerCase();
    setIsPower(url.includes('power'));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await login(username, password, apiVersion);
      if (data.adminId) {
        localStorage.setItem('adminId', data.adminId);
      }
      localStorage.setItem('username', username);
      window.location.href = '/dashboard';
    } catch (err) {
      toast.error(err.message);
      console.error('Login error:', err);
    } finally {
      setIsLoading(false);
    }
  };

    return (
      <div className="login-container min-vh-100 d-flex align-items-center justify-content-center">
        <div className="background-overlay"></div>
        <Form onSubmit={handleSubmit} className="login-form-container">
          <div className="text-center mb-5">
            <h1 className="display-3 fw-bold text-warning mb-2">Power Bingo</h1>
            <div className="bingo-balls">
              <span className="bingo-ball">B</span>
              <span className="bingo-ball">I</span>
              <span className="bingo-ball">N</span>
              <span className="bingo-ball">G</span>
              <span className="bingo-ball">O</span>
            </div>
          </div>

          <div className="glass-morphism p-4 rounded-lg">
            <div className="form-outline mb-4">
              <input
                type="text"
                id="form1Example13"
                className="form-control form-control-lg bg-transparent text-white"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
              />
            </div>

            <div className="form-outline mb-4">
              <input
                type="password"
                id="form1Example23"
                className="form-control form-control-lg bg-transparent text-white"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </div>

            <button 
              type="submit" 
              className="btn btn-warning btn-lg w-100 mt-4 fw-bold"
              disabled={isLoading || !username.includes('power')}
            >
              {isLoading ? (
                <span>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Signing in...
                </span>
              ) : 'Sign in'}
            </button>
          </div>
        </Form>
        <ToastContainer />
      </div>
    );
}

export default Login;
