const API_KEY = '8f6963a7b5a69e2ec18df35a488c356438aaf5e2a7bc71dae66ca8a9089df537';
const token = localStorage.getItem('token');

const api = {
  fetch: async (url, options = {}) => {

    const headers = {
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
      'Authorization': `Bearer ${token}`,
      ...options.headers,
    };

    const response = await fetch(url, {
      ...options,
      headers,
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'API request failed');
    }

    return response.json();
  },

  get: (url) => api.fetch(url),

  post: (url, data) => {
    return api.fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  },

  put: (url, data) =>
    api.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
    }),

  delete: (url) =>
    api.fetch(url, { method: 'DELETE' }),
};

const handleApiError = (error) => {
    if (error.message.includes('IP is blocked')) {
      window.location.href = '/blocked';
    } else if (error.message.includes('Unauthorized')) {
      window.location.href = '/login';
    } else {
      console.error('API Error:', error);
    }
  };

export default api;