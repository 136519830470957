import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Form, Button, Table } from 'react-bootstrap';
import api from '../api';

const API = process.env.REACT_APP_API;

const CommissionSettings = ({ show, onHide, adminId }) => {
  const [settings, setSettings] = useState({
    defaultRate: 0.2,
    dynamicRatesEnabled: false,
    betAmountTiers: [],
    playerCountTiers: [],
    timeTiers: [],
    minRate: 0.1,
    maxRate: 0.3
  });

  const [activeTab, setActiveTab] = useState('bet');

  const [newBetTier, setNewBetTier] = useState({
    minValue: '',
    maxValue: '',
    rate: ''
  });

  const [newPlayerTier, setNewPlayerTier] = useState({
    minValue: '',
    maxValue: '',
    rate: ''
  });

  const [newTimeTier, setNewTimeTier] = useState({
    startHour: '',
    endHour: '',
    rate: ''
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (show && adminId) {
      fetchSettings();
    }
  }, [show, adminId]);

  const fetchSettings = async () => {
    try {
      const response = await api.get(`${API}/admin/${adminId}/commission-settings`);
      setSettings(response.commissionSettings);
    } catch (error) {
      setError('Failed to load commission settings');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveSettings = async () => {
    try {
      await api.put(`${API}/admin/${adminId}/commission-settings`, settings);
      setSuccess('Settings saved successfully');
      setTimeout(() => {
        setSuccess('');
        onHide();
      }, 1500);
    } catch (error) {
      setError('Failed to save settings');
      setTimeout(() => setError(''), 3000);
    }
  };

  const addTimeTier = () => {
    if (!newTimeTier.startHour || !newTimeTier.endHour || !newTimeTier.rate) {
      setError('Please fill all time tier fields');
      return;
    }

    const startHour = parseInt(newTimeTier.startHour);
    const endHour = parseInt(newTimeTier.endHour);

    if (startHour > endHour) {
      setError('Start hour must be before end hour');
      return;
    }

    const tierData = {
      startHour,
      endHour,
      rate: parseFloat(newTimeTier.rate) / 100 // Convert percentage to decimal
    };

    setSettings(prev => ({
      ...prev,
      timeTiers: [...prev.timeTiers, tierData]
    }));

    setNewTimeTier({ startHour: '', endHour: '', rate: '' });
  };

  const removeTimeTier = (index) => {
    setSettings(prev => ({
      ...prev,
      timeTiers: prev.timeTiers.filter((_, i) => i !== index)
    }));
  };

  const addBetTier = () => {
    if (!newBetTier.minValue || !newBetTier.maxValue || !newBetTier.rate) {
      setError('Please fill all bet tier fields');
      return;
    }

    const tierData = {
      minValue: parseFloat(newBetTier.minValue),
      maxValue: parseFloat(newBetTier.maxValue),
      rate: parseFloat(newBetTier.rate) / 100 // Convert percentage to decimal
    };

    setSettings(prev => ({
      ...prev,
      betAmountTiers: [...prev.betAmountTiers, tierData]
    }));

    setNewBetTier({ minValue: '', maxValue: '', rate: '' });
  };

  const addPlayerTier = () => {
    if (!newPlayerTier.minValue || !newPlayerTier.maxValue || !newPlayerTier.rate) {
      setError('Please fill all player tier fields');
      return;
    }

    const tierData = {
      minValue: parseFloat(newPlayerTier.minValue),
      maxValue: parseFloat(newPlayerTier.maxValue),
      rate: parseFloat(newPlayerTier.rate) / 100 // Convert percentage to decimal
    };

    setSettings(prev => ({
      ...prev,
      playerCountTiers: [...prev.playerCountTiers, tierData]
    }));

    setNewPlayerTier({ minValue: '', maxValue: '', rate: '' });
  };

  const removeBetTier = (index) => {
    setSettings(prev => ({
      ...prev,
      betAmountTiers: prev.betAmountTiers.filter((_, i) => i !== index)
    }));
  };

  const removePlayerTier = (index) => {
    setSettings(prev => ({
      ...prev,
      playerCountTiers: prev.playerCountTiers.filter((_, i) => i !== index)
    }));
  };

  const renderTierTab = () => {
    if (activeTab === 'time') {
        return (
          <div className="mt-4">
            {/* Input Form */}
            <div className="flex flex-col gap-2 mb-4">
              <div className="grid grid-cols-2 gap-2">
                <select
                  className="bg-gray-800 border border-gray-700 rounded px-2 py-1 text-sm"
                  value={newTimeTier.startHour}
                  onChange={(e) => setNewTimeTier(prev => ({ 
                    ...prev, 
                    startHour: e.target.value 
                  }))}
                >
                  <option value="">መጀመሪያ ሰዓት</option>
                  {Array.from({ length: 24 }, (_, i) => (
                    <option key={i} value={i}>
                      {i.toString().padStart(2, '0')}:00
                    </option>
                  ))}
                </select>
                <select
                  className="bg-gray-800 border border-gray-700 rounded px-2 py-1 text-sm"
                  value={newTimeTier.endHour}
                  onChange={(e) => setNewTimeTier(prev => ({ 
                    ...prev, 
                    endHour: e.target.value 
                  }))}
                >
                  <option value="">መጨረሻ ሰዓት</option>
                  {Array.from({ length: 24 }, (_, i) => (
                    <option key={i} value={i}>
                      {i.toString().padStart(2, '0')}:00
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex gap-2">
                <select
                  className="flex-1 bg-gray-800 border border-gray-700 rounded px-2 py-1 text-sm"
                  value={newTimeTier.rate}
                  onChange={(e) => setNewTimeTier(prev => ({ 
                    ...prev, 
                    rate: e.target.value 
                  }))}
                >
                  <option value="">ኮሚሽን መጠን</option>
                  {Array.from({ length: 71 }, (_, i) => i + 5).map(value => (
                    <option key={value} value={value}>
                      {value}%
                    </option>
                  ))}
                </select>
                <button
                  onClick={addTimeTier}
                  className="w-20 bg-blue-500 hover:bg-blue-600 text-white rounded px-3 py-1 text-sm"
                >
                  Add
                </button>
              </div>
            </div>
  
            {/* Tiers Table */}
            <div className="overflow-x-auto">
              <table className="w-full text-sm">
                <thead>
                  <tr className="bg-gray-700">
                    <th className="px-2 py-1">ሰዓት</th>
                    <th className="px-2 py-1">ኮሚሽን</th>
                    <th className="w-10"></th>
                  </tr>
                </thead>
                <tbody>
                  {settings.timeTiers.map((tier, index) => (
                    <tr key={index} className="border-t border-gray-700">
                      <td className="px-2 py-1">
                        {tier.startHour.toString().padStart(2, '0')}:00 - {tier.endHour.toString().padStart(2, '0')}:00
                      </td>
                      <td className="px-2 py-1">{(tier.rate * 100).toFixed(0)}%</td>
                      <td className="px-2 py-1">
                        <button
                          onClick={() => removeTimeTier(index)}
                          className="text-red-500 hover:text-red-600 text-sm"
                        >
                          ×
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      }
     else if (activeTab === 'bet') {
      return (
        <div className="mt-4">
          {/* Input Form */}
          <div className="flex flex-col gap-2 mb-4">
            <div className="grid grid-cols-2 gap-2">
              <input
                placeholder="ዝቅተኛ ውርርድ"
                className="bg-gray-800 border border-gray-700 rounded px-2 py-1 text-sm"
                value={newBetTier.minValue}
                onChange={(e) => setNewBetTier(prev => ({ 
                  ...prev, 
                  minValue: e.target.value 
                }))}
              />
              <input
                placeholder="ከፍተኛ ውርርድ"
                className="bg-gray-800 border border-gray-700 rounded px-2 py-1 text-sm"
                value={newBetTier.maxValue}
                onChange={(e) => setNewBetTier(prev => ({ 
                  ...prev, 
                  maxValue: e.target.value 
                }))}
              />
            </div>
            <div className="flex gap-2">
              <select
                className="flex-1 bg-gray-800 border border-gray-700 rounded px-2 py-1 text-sm"
                value={newBetTier.rate}
                onChange={(e) => setNewBetTier(prev => ({ 
                  ...prev, 
                  rate: e.target.value 
                }))}
              >
                <option value="">ኮሚሽን መጠን</option>
                {Array.from({ length: 71 }, (_, i) => i + 5).map(value => (
                  <option key={value} value={value}>
                    {value}%
                  </option>
                ))}
              </select>
              <button
                onClick={addBetTier}
                className="w-20 bg-blue-500 hover:bg-blue-600 text-white rounded px-3 py-1 text-sm"
              >
                Add
              </button>
            </div>
          </div>
  
          {/* Tiers Table */}
          <div className="overflow-x-auto">
            <table className="w-full text-sm">
              <thead>
                <tr className="bg-gray-700">
                  <th className="px-2 py-1">ውርርድ</th>
                  <th className="px-2 py-1">ኮሚሽን</th>
                  <th className="w-10"></th>
                </tr>
              </thead>
              <tbody>
                {settings.betAmountTiers.map((tier, index) => (
                  <tr key={index} className="border-t border-gray-700">
                    <td className="px-2 py-1">
                      {tier.minValue} - {tier.maxValue}
                    </td>
                    <td className="px-2 py-1">{(tier.rate * 100).toFixed(0)}%</td>
                    <td className="px-2 py-1">
                      <button
                        onClick={() => removeBetTier(index)}
                        className="text-red-500 hover:text-red-600 text-sm"
                      >
                        ×
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return (
        <div className="mt-4">
          {/* Input Form */}
          <div className="flex flex-col gap-2 mb-4">
            <div className="grid grid-cols-2 gap-2">
              <input
                placeholder="ዝቅተኛ ተጫዋች"
                className="bg-gray-800 border border-gray-700 rounded px-2 py-1 text-sm"
                value={newPlayerTier.minValue}
                onChange={(e) => setNewPlayerTier(prev => ({ 
                  ...prev, 
                  minValue: e.target.value 
                }))}
              />
              <input
                placeholder="ከፍተኛ ተጫዋች"
                className="bg-gray-800 border border-gray-700 rounded px-2 py-1 text-sm"
                value={newPlayerTier.maxValue}
                onChange={(e) => setNewPlayerTier(prev => ({ 
                  ...prev, 
                  maxValue: e.target.value 
                }))}
              />
            </div>
            <div className="flex gap-2">
              <select
                className="flex-1 bg-gray-800 border border-gray-700 rounded px-2 py-1 text-sm"
                value={newPlayerTier.rate}
                onChange={(e) => setNewPlayerTier(prev => ({ 
                  ...prev, 
                  rate: e.target.value 
                }))}
              >
                <option value="">ኮሚሽን መጠን</option>
                {Array.from({ length: 71 }, (_, i) => i + 5).map(value => (
                  <option key={value} value={value}>
                    {value}%
                  </option>
                ))}
              </select>
              <button
                onClick={addPlayerTier}
                className="bg-blue-500 hover:bg-blue-600 text-white rounded px-3 py-1 text-sm"
              >
                Add
              </button>
            </div>
          </div>
  
          {/* Tiers Table */}
          <div className="overflow-x-auto">
            <table className="w-full text-sm">
              <thead>
                <tr className="bg-gray-700">
                  <th className="px-2 py-1">ተጫዋች</th>
                  <th className="px-2 py-1">ኮሚሽን</th>
                  <th className="w-10"></th>
                </tr>
              </thead>
              <tbody>
                {settings.playerCountTiers.map((tier, index) => (
                  <tr key={index} className="border-t border-gray-700">
                    <td className="px-2 py-1">
                      {tier.minValue} - {tier.maxValue}
                    </td>
                    <td className="px-2 py-1">{(tier.rate * 100).toFixed(0)}%</td>
                    <td className="px-2 py-1">
                      <button
                        onClick={() => removePlayerTier(index)}
                        className="text-red-500 hover:text-red-600 text-sm"
                      >
                        ×
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  };

  if (loading && show) {
    return (
      <Modal show={show} onHide={onHide} size="lg" className="text-white">
        <div className="flex justify-center items-center p-8">
          Loading...
        </div>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={onHide} size="sm" className="text-white">
      <div className="bg-gray-900 text-white">
        <div className="border-b border-gray-800 px-6 py-2">
          <h3 className="text-xl font-semibold">Commission Settings</h3>
        </div>

        <div className="p-6">
          {error && (
            <div className="bg-red-500 text-white px-4 py-2 rounded mb-4">
              {error}
            </div>
          )}
          {success && (
            <div className="bg-green-500 text-white px-4 py-2 rounded mb-4">
              {success}
            </div>
          )}

          <div className="space-y-6">
            {/* Default Rate and Dynamic Toggle */}
            <div className="grid grid-cols-1 gap-4">
              <div className='flex flex-row gap-2'>
                <label className="block text-sm font-medium">
                  Commission Rate (%)
                </label>
                <select
                    className="w-24 bg-gray-800 border border-gray-700 rounded px-2 py-1"
                    value={settings.defaultRate * 100}
                    onChange={(e) => setSettings(prev => ({ 
                        ...prev, 
                        defaultRate: parseFloat(e.target.value) / 100 
                    }))}
                    >
                    {Array.from({ length: 71 }, (_, i) => i + 5).map(value => (
                        <option key={value} value={value}>
                        {value}%
                        </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="flex items-center">
                <label className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-blue-600"
                    checked={settings.dynamicRatesEnabled}
                    onChange={(e) => setSettings(prev => ({ 
                      ...prev, 
                      dynamicRatesEnabled: e.target.checked 
                    }))}
                  />
                  <span className="ml-2">የሚቀያየር ኮሚሽን</span>
                </label>
              </div>
            {/* <div className="flex items-center gap-4 mt-4">
                <div className='flex items-center gap-2'>
                    <label className="text-sm whitespace-nowrap">
                    Min Rate:
                    </label>
                    <select
                    className="w-16 bg-gray-800 border border-gray-700 px-1 py-1 rounded"
                    value={settings.minRate * 100}
                    onChange={(e) => setSettings(prev => ({ 
                        ...prev, 
                        minRate: parseFloat(e.target.value) / 100 
                    }))}
                    >
                    {Array.from({ length: 71 }, (_, i) => i + 5).map(value => (
                        <option key={value} value={value}>
                        {value}%
                        </option>
                    ))}
                    </select>
                </div>
                <div className='flex items-center gap-2'>
                    <label className="text-sm whitespace-nowrap">
                    Max Rate:
                    </label>
                    <select
                    className="w-16 bg-gray-800 border border-gray-700 px-1 py-1 rounded"
                    value={settings.maxRate * 100}
                    onChange={(e) => setSettings(prev => ({ 
                        ...prev, 
                        maxRate: parseFloat(e.target.value) / 100 
                    }))}
                    >
                    {Array.from({ length: 71 }, (_, i) => i + 5).map(value => (
                        <option key={value} value={value}>
                        {value}%
                        </option>
                    ))}
                    </select>
                </div>
            </div> */}
            {settings.dynamicRatesEnabled && (
              <>
                {/* Tabs */}
                <div className="flex space-x-4 border-b border-gray-700">
                  <button
                    className={`py-2 px-2 font-medium text-sm ${
                      activeTab === 'bet'
                        ? 'text-blue-500 border-b-2 border-blue-500'
                        : 'text-gray-400 hover:text-gray-300'
                    }`}
                    onClick={() => setActiveTab('bet')}
                  >
                    በውርርድ መጠን
                  </button>
                  <button
                    className={`py-2 px-2 font-medium text-sm ${
                      activeTab === 'player'
                        ? 'text-blue-500 border-b-2 border-blue-500'
                        : 'text-gray-400 hover:text-gray-300'
                    }`}
                    onClick={() => setActiveTab('player')}
                  >
                    በተጫዋች ብዛት
                  </button>
                  <button
                    className={`py-2 px-2 font-medium text-sm ${
                        activeTab === 'time'
                        ? 'text-blue-500 border-b-2 border-blue-500'
                        : 'text-gray-400 hover:text-gray-300'
                    }`}
                    onClick={() => setActiveTab('time')}
                    >
                    በሰዓት
                  </button>
                </div>

                {/* Tab Content */}
                {renderTierTab()}
              </>
            )}

          </div>
        </div>

        <div className="border-t border-gray-800 px-6 py-4 flex justify-end space-x-4">
          <button
            onClick={onHide}
            className="px-4 py-1 bg-gray-700 hover:bg-gray-600 rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleSaveSettings}
            className="px-4 py-1 bg-blue-500 hover:bg-blue-600 rounded"
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CommissionSettings;