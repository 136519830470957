import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './DashBoard.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaChevronDown, FaSync, FaTimes } from 'react-icons/fa'; 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BonusModal from './BonusModal'; 
import api from '../api';
import { Table } from 'react-bootstrap';

const DashboardPage = () => {
  const [bettingAmount, setBettingAmount] = useState(localStorage.getItem('bettingAmount') || '');
  const [audioLanguage, setAudioLanguage] = useState(localStorage.getItem('audioLanguage') || '');
  const [callSpeed, setCallSpeed] = useState(localStorage.getItem('callSpeed') || '');
  const [winningPattern, setWinningPattern] = useState(localStorage.getItem('winningPattern') || '');
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [gameType, setGameType] = useState(localStorage.getItem('storedGameType') || 'regular');
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [slideshowTables, setSlideshowTables] = useState([]);
  const urlPh = window.location.href;
  const url = window.location.href;

  const openInNewTab = (url) => {
    if (url.includes('power')) {
      const token = localStorage.getItem('token');
      const selectedNumbers = JSON.parse(localStorage.getItem('selectedNumbers') || '[]');
      const queryParams = new URLSearchParams({
        token: token,
        selectedNumbers: JSON.stringify(selectedNumbers)
      }).toString();
      url = `${url}?${queryParams}`;
    }
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    const storedGameType = localStorage.getItem('gameType');
    if (storedGameType) {
      setGameType(storedGameType);
    }
  }, []);

  const API = process.env.REACT_APP_API;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);


  const winningPatterns = [
    [1, 0, 0, 0, 0, 6, 0, 0, 0, 0, 10, 0, 12, 0, 0, 15, 0, 0, 0, 0, 21, 0, 0, 0, 0],
    [0, 2, 0, 0, 0, 0, 7, 0, 0, 0, 0, 11, 12, 0, 0, 0, 17, 0, 0, 0, 0, 22, 0, 0, 0],
    [0, 0, 3, 0, 0, 0, 0, 8, 0, 0, 0, 0, 12, 0, 0, 0, 0, 18, 0, 0, 0, 0, 23, 0, 0],
    [0, 0, 0, 4, 0, 0, 0, 0, 9, 0, 0, 0, 12, 13, 0, 0, 0, 0, 19, 0, 0, 0, 0, 24, 0],
    [0, 0, 0, 0, 5, 0, 0, 0, 0, 10, 0, 0, 12, 0, 14, 0, 0, 0, 0, 20, 0, 0, 0, 0, 25],
    
    [1, 2, 3, 4, 5, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 6, 7, 8, 9, 10, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 11, 13, 12, 14, 15, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 16, 17, 18, 19, 20, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 21, 22, 23, 24, 25],

    [1, 0, 0, 0, 0, 0, 7, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 19, 0, 0, 0, 0, 0, 25],
    [0, 0, 0, 0, 5, 0, 0, 0, 10, 0, 0, 0, 12, 0, 0, 0, 17, 0, 0, 0, 21, 0, 0, 0, 0],

    [1, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 21, 0, 0, 0, 25],
    [0, 0, 0, 0, 0, 0, 7, 0, 9, 0, 0, 0, 12, 0, 0, 0, 16, 0, 18, 0, 0, 0, 0, 0, 0],
  ];

  useEffect(() => {
      const tables = winningPatterns.map((pattern) => generateTable(pattern));
      setSlideshowTables(tables);
    }, []);

  const handleNumberSelect = (number) => {
    if (selectedNumbers.includes(number)) {
      const updatedNumbers = selectedNumbers.filter((n) => n !== number);
      setSelectedNumbers(updatedNumbers);
      localStorage.setItem('selectedNumbers', JSON.stringify(updatedNumbers));
    } else {
      setSelectedNumbers([...selectedNumbers, number]);
      const updatedNumbers = [...selectedNumbers, number];
      setSelectedNumbers(updatedNumbers);
      localStorage.setItem('selectedNumbers', JSON.stringify(updatedNumbers));
    }
  };

  const ReloadIcon = ({ onClick }) => {
    return (
      <FaSync
        className="text-white w-5 h-5 cursor-pointer hover:rotate-180 transition-transform duration-500 ease-in-out"
        onClick={onClick}
      />
    );
  };

  const handleReloadNumbers = () => {
    const storedNumbers = localStorage.getItem('selectedNumbers');
    if (storedNumbers) {
      setSelectedNumbers(JSON.parse(storedNumbers));
    }
  };

  const handleStartGame = async () => {
    setIsLoading(true);
    try {  
      const response = await api.post(`${API}/game/start`, {
        bettingAmount: parseInt(bettingAmount),
        cardPaletteNumbers: selectedNumbers,
        callSpeed: parseInt(callSpeed),
        gameType: gameType,
        houseCardCount: gameType === 'house' ? parseInt(selectedNumbers.length) : undefined,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      navigate('/game-in-progress', { state: { gameId: response.gameId, calledNumbers: response.calledNumbers, playingCards: response.playingCards, payoutToWinner: response.payoutToWinner, callSpeed, audioLanguage, gameType, 
        houseCards: response.houseCards, callsToWin: response.winningCalls,  winningCalls: response.winningCalls,
        winningCard: response.winningCard,
        houseWin: response.houseWin } });
    } catch (error) {
      console.error('Error starting the game:', error);
      if (error.response.status === 400 && error.response.data.message == 'Insufficient balance in wallet') {
        alert('Insufficient balance in wallet');
      }
    }finally {
      setIsLoading(false);
    }
  };

  const handleLocalStorage = () => {
      localStorage.setItem('bettingAmount', bettingAmount);
      localStorage.setItem('callSpeed', callSpeed);
      localStorage.setItem('audioLanguage', audioLanguage);
      localStorage.setItem('winningPattern', winningPattern);
    };
  
  const generateTable = (pattern) => {
    const cellSize = 25;
    const dotSize = 25;
  
    const cells = pattern.map((value, index) => (
      <td
        className="position-relative bg-light border border-secondary"
        style={{
          padding: '15px 20px',
          width: `25px`,
          height: `26px`,
        }}
      >
        <div
          key={index}
          className="text-center position-absolute top-50 start-50 translate-middle"
          style={{
            width: `${dotSize}px`,
            height: `${dotSize}px`,
            backgroundColor: value === 12 ? 'yellow' : value > 0 ? 'blue' : 'transparent',
            borderRadius: '50%',
          }}
        ></div>
      </td>
    ));
  
    const rows = [];
    for (let i = 0; i < 5; i++) {
      rows.push(
        <tr key={i} className='m-0 p-0'>
          {cells.slice(i * 5, (i + 1) * 5)}
        </tr>
      );
    }
  
    const headerCells = ['B', 'I', 'N', 'G', 'O'].map((letter, index) => (
      <th
        key={index}
        className="fw-bold text-center border border-white"
        style={{
          backgroundColor: ['#3B82F6', '#EF4444', '#F97316', '#22C55E', '#ffc900'][index],
          width: `10px`,
          height: `10px`,
          fontSize: '50',
        }}
      >
        {letter}
      </th>
    ));
  
    return (
      <Table className="mb-0 pb-0">
        <thead>
          <tr>{headerCells}</tr>
        </thead>
        <tbody className="mb-0 pb-0">{rows}</tbody>
      </Table>
    );
  };
  const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      cssEase: 'linear',
      arrows: false,
  };

  const ProfileIcon = ({ onClick }) => {
    return (
      <div className='d-flex'>
      <div
        style={{
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          backgroundColor: '#ccc',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={onClick}
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
          alt="Profile"
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: '#fff'
          }}
        />
      </div>
      </div>
    );
  };

  const handleProfileClick = () => {
      navigate('/reports')
    };

  return (
    <div style={{backgroundColor: "#242424" }}>
      <ToastContainer />
      <BonusModal />
      <div className="container-fluid min-h-screen w-[90%] bg-[#242424] ml-[15%] mr-[2%] pt-[2%] pl-5">
         <div style={{
            position: 'absolute',
            top: '3%',
            left: '5%',
            display: 'flex',
            gap: '20px',
            zIndex: 1000
          }}>
            <button
              onClick={() => openInNewTab('https://www.powerspin.xyz/')}
              style={{
                padding: '8px 12px',
                backgroundColor: '#FFD700',
                color: '#000',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontWeight: 'bold',
                boxShadow: '0 0 10px rgba(184, 134, 11, 0.5)',
                transition: 'all 0.3s ease'
              }}
              onMouseOver={(e) => e.target.style.backgroundColor = '#FFA500'}
              onMouseOut={(e) => e.target.style.backgroundColor = '#FFD700'}
            >
              Power Fortune
            </button>
            <button
              onClick={() => openInNewTab('https://www.powerbonus.xyz/')}
              style={{
                padding: '10px 15px',
                backgroundColor: '#B8860B',
                color: '#FFF',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontWeight: 'bold',
                boxShadow: '0 0 10px rgba(184, 134, 11, 0.5)',
                transition: 'all 0.3s ease'
              }}
              onMouseOver={(e) => e.target.style.backgroundColor = '#DAA520'}
              onMouseOut={(e) => e.target.style.backgroundColor = '#B8860B'}
              disabled
            >
              Power Dice
            </button>
            <button
              onClick={() => openInNewTab('https://www.powerbonus.xyz/')}
              style={{
                padding: '10px 15px',
                backgroundColor: '#B8860B',
                color: '#FFF',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontWeight: 'bold',
                boxShadow: '0 0 10px rgba(184, 134, 11, 0.5)',
                transition: 'all 0.3s ease'
              }}
              onMouseOver={(e) => e.target.style.backgroundColor = '#DAA520'}
              onMouseOut={(e) => e.target.style.backgroundColor = '#B8860B'}
              disabled
            >
              Power Lottery
            </button>
            <button
              onClick={() => openInNewTab('https://www.powerbonus.xyz/')}
              style={{
                padding: '10px 15px',
                backgroundColor: '#B8860B',
                color: '#FFF',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontWeight: 'bold',
                boxShadow: '0 0 10px rgba(184, 134, 11, 0.5)',
                transition: 'all 0.3s ease'
              }}
              onMouseOver={(e) => e.target.style.backgroundColor = '#DAA520'}
              onMouseOut={(e) => e.target.style.backgroundColor = '#B8860B'}
              disabled
            >
              Power Raffle
            </button>
          </div>
      
        <div className="row p-4 mx-4 mt-4 mb-0">
          <div className="col-8 py-1 px-4" style={{ minHeight: "100vh", backgroundColor: "#161616"}}>
            <div className="row pb-3 text-white">
            <div className="col-lg-12">
              <div className="row pb-1">
                <div className="col-6">
                  <label htmlFor="bettingAmount" className="form-label ">
                    Bet Amount
                  </label>
                  <input
                    list="bettingAmountOptions"
                    className="form-control text-white"
                    style={{backgroundColor: '#475569', border: 'none', borderRadius: 0}}
                    value={bettingAmount}
                    onChange={(e) => setBettingAmount(e.target.value)}
                    placeholder="Select or type Bet Amount"
                    onBlur={handleLocalStorage}
                  />
                  <datalist id="bettingAmountOptions">
                    <option value="10"></option>
                    <option value="15"></option>
                    <option value="20"></option>
                    <option value="25"></option>
                    <option value="50"></option>
                    <option value="100"></option>
                    <option value="150"></option>
                    <option value="200"></option>
                  </datalist>
                </div>
                <div className="col-6">
                  <label htmlFor="payoutToWinner" className="form-label">
                    Winning Pattern
                  </label>
                  <select
                    id="winningPattern"
                    className="form-select text-white"
                    style={{backgroundColor: '#475569', border: 'none', borderRadius: 0}}
                    onBlur={handleLocalStorage}
                    value={winningPattern}
                    onChange={(e) => setWinningPattern(e.target.value)}
                  >
                    <option value="">select an option</option>
                    <option value="All">All Common Patterns</option>
                    <option value="Am">Full House</option>
                    <option value="Am">L Shape</option>
                    <option value="Am">Both Diagonal Line</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="audioLanguage" className="form-label">
                    Audio Language
                  </label>
                  <select
                    id="audioLanguage"
                    className="form-select text-white"
                    value={audioLanguage}
                    onChange={(e) => setAudioLanguage(e.target.value)}
                    style={{backgroundColor: '#475569', border: 'none', borderRadius: 0}}
                    onBlur={handleLocalStorage}
                  >
                    <option value="">select an option</option>
                    <option value="male">Amharic Male</option>
                    <option value="female">Amharic Female</option>
                  </select>
                </div>
                <div className="col-6">
                  <label htmlFor="callSpeed" className="form-label">
                    Call Speed
                  </label>
                  <select
                    style={{backgroundColor: '#475569', border: 'none', borderRadius: 0}}
                    id="callSpeed"
                    className="form-select text-white" 
                    value={callSpeed}
                    onChange={(e) => setCallSpeed(e.target.value)}
                    onBlur={handleLocalStorage}
                  >
                    <option value="">Select Call Speed</option>
                    <option value="5000">5 seconds</option>
                    <option value="6000">6 seconds</option>
                    <option value="7000">7 seconds</option>
                    <option value="10000">10 seconds</option>
                    <option value="15000">15 seconds</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-start my-1 mb-3">
            <ReloadIcon onClick={handleReloadNumbers} />
          </div>
          <div className="container m-0 p-0" style={{ width: '100%', maxHeight: "400px", overflowY: "scroll", border: '1px solid #94A3B8', borderColor: '#94A3B8', borderRadius: '5px', scrollbarWidth: 'thin', scrollbarColor: '#94A3B8 #141414' }}>
          {Array.from({ length: 10 }, (_, i) => (
            <div key={i} className="row m-0 p-0" style={{fontSize: 15}}>
              {Array.from({ length: 10 }, (_, j) => (
                <div key={j} className="col-md-1 m-1">
                  <button
                    style={{
                      fontSize: 25,
                      color: '#94A3B8',
                      fontWeight: 600,
                      width: '45px',  // Fixed width
                      height: '45px', // Fixed height
                      padding: 0,     // Remove padding
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    className={`btn ${
                      selectedNumbers.includes(i * 10 + j + 1) ? 'btn-primary text-white' : ''
                    }`}
                    onClick={() => handleNumberSelect(i * 10 + j + 1)}
                  >
                    {i * 10 + j + 1}
                  </button>
                </div>
              ))}
            </div>
          ))}
          </div>
        </div>
        <div className="col-3 px-3 mx-auto d-flex flex-column">
          <div className="card bg-dark">
            <div className="card-body p-0 m-0">
              <Slider {...settings}>
                {slideshowTables.map((table, index) => (
                  <div key={index}>{table}</div>
                ))}
              </Slider>
            </div>
          </div>
          
          <a href="https://power-games.site/"
            target="_blank"
            className="inline-block px-1 py-1 mt-4 text-md text-decoration-none text-center font-bold text-white uppercase bg-gradient-to-r from-yellow-400 to-red-600 rounded-full shadow-lg hover:from-yellow-500 hover:to-red-700 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-100"
          >
            Play Online Bingo!
          </a>
          <button
            className="btn btn-warning btn-lg w-100"
            style={{marginTop: '75%'}}
            onClick={handleStartGame}
            disabled={!bettingAmount || !callSpeed || selectedNumbers.length < 3 || isLoading || bettingAmount < 10}
          >
            {isLoading ? 'Starting Game...' : 'Start Game'}
          </button>
        </div>
        <div className="col-1 mr-auto d-flex flex-row justify-content-end">
          <ProfileIcon onClick={handleProfileClick} className="text-end"/>
        </div>
      </div>
    </div>
    </div>
  );
}

export default DashboardPage;
