import React, { useState, useEffect } from 'react';
import Card from './Card';
import api from '../api';

function AllCards() {
  const [cards, setCards] = useState([]);
  const API = process.env.REACT_APP_API;

  useEffect(() => {

    async function fetchCards() {
      try {
        let apiEndpoint = API;


        const endpoint = `${apiEndpoint}/card/getCards`;

        const response = api.get(endpoint);
        const data = await response;
        
        // Sort the cards by palette number
        const sortedCards = data.sort((a, b) => a.paletteNumber - b.paletteNumber);
        setCards(sortedCards);
      } catch (error) {
        console.error('Error fetching cards:', error);
      }
    }
    fetchCards();
  }, [API]);

  return (
    <div>
      {cards.slice(0, 100).map(card => (
        <Card key={card.paletteNumber} card={card} />
      ))}
    </div>
  );
}

export default AllCards;