import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import api from '../api';
import LoadingOverlay from './LoadingOverlay';

function AdminStats() {
  const [stats, setStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'
    ];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`; 
  }

  const API = process.env.REACT_APP_API;
  useEffect(() => {
    const fetchAllStats = async () => {
      try {
        const response = await api.get(`${API}/admin/stats`);
        setStats(response.statsByPeriod);
      } catch (error) {
        console.error('Error fetching stats:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllStats();
  }, []);

  if (isLoading) {
    return <LoadingOverlay />;
  }


  return (
    <div className="p-8">
    <div className="bg-gray-900 rounded-xl shadow-2xl overflow-hidden border border-gray-800">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-800">
          <thead>
            <tr>
              <th className="bg-black/80 px-6 py-4 text-left text-sm font-semibold text-gray-300 uppercase tracking-wider">
                Date
              </th>
              <th className="bg-black/80 px-6 py-4 text-left text-sm font-semibold text-gray-300 uppercase tracking-wider">
                Profit
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-800 bg-gray-900">
            {stats
              .sort((a, b) => new Date(b[0]) - new Date(a[0]))
              .map((data, index) => (
                <tr 
                  key={index}
                  className="hover:bg-gray-800/50 transition-colors duration-200"
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                    {formatDate(data.date)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="text-sm font-medium text-green-400">
                      {data.profit.toLocaleString()} 
                      <span className="text-gray-500 ml-1">Birr</span>
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  );
}

export default AdminStats;
