import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import { FaGamepad } from 'react-icons/fa';
import CryptoJS from 'crypto-js'
import api from '../api';

function AdminGames() {
  const [games, setGames] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalGames, setTotalGames] = useState(0);
  

  const API = process.env.REACT_APP_API;

  const [stats, setStats] = useState({
    statsByPeriod: [],
    totalProfit: 0,
    totalBetAmount: 0,
    totalGames: 0,
    wallet: 0
  });
  
  useEffect(() => {
    const fetchStats = async () => {
      const response = await api.get(`${API}/admin/stats`);
      const data = await response;
      setStats(data);
    };

    fetchStats();
  }, [API]);

  useEffect(() => {
    fetchGames();
  }, [page]);

  const fetchGames = async () => {
    try {
      setLoading(true);
      const response = await api.get(`${API}/admin/myGames?page=${page}`);
      
      const data = await response;
      if (page === 1) {
        setGames(data.games);
      } else {
        setGames(prevGames => [...prevGames, ...data.games]);
      }
      setTotalPages(data.totalPages);
      setTotalGames(data.totalGames);
      setLoading(false);
    } catch(error) {
      console.log(error);
      setLoading(false);
    }
  }

  const calculateTotalBetAmount = (game) => {
    if (Array.isArray(game.bettingAmount)) {
      return game.bettingAmount.reduce((total, amount) => total + amount, 0);
    }
    return game.bettingAmount;
  };

  const calculateProfit = (game) => {
    if (Array.isArray(game.bettingAmount)) {
      const totalBet = calculateTotalBetAmount(game);
      return totalBet * 0.2;
    }
    // For regular games
    return game.bettingAmount * (game.players + (game.houseCardCount || 0)) * 0.2;
  };

  const formatBetAmount = (game) => {
    if (Array.isArray(game.bettingAmount)) {
      const uniqueAmounts = [...new Set(game.bettingAmount)];
      return uniqueAmounts
        .map(amount => `${amount} Birr`)
        .join(', ');
    }
    return `${game.bettingAmount} Birr`;
  };

  const loadMoreGames = () => {
    if (page < totalPages) {
      setPage(prevPage => prevPage + 1);
    }
  }

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const year = date.getFullYear();
    const month = months[date.getMonth()]; 
    const day = date.getDate();
    const dayOfWeek = days[date.getDay()];
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  
    return `${dayOfWeek}, ${month} ${day}, ${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`; 
  }
  
  return (
    <div className="p-8 space-y-8 bg-gray-900 rounded-lg shadow-2xl">
  {/* Header Section */}
  <h2 className="text-3xl font-bold text-white tracking-tight">Statistics</h2>
  
  {/* Stats Cards */}
  <div className="flex gap-6">
    <div className="flex items-center p-6 bg-black/50 rounded-2xl backdrop-blur-sm shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-800">
      <FaGamepad className="text-4xl text-blue-500 mr-4" />
      <div className="flex flex-col">
        <span className="text-gray-400 text-sm">Total Games</span>
        <span className="text-white text-2xl font-bold">{stats.totalGames}</span>
      </div>
    </div>
    
    <div className="flex items-center p-6 bg-black/50 rounded-2xl backdrop-blur-sm shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-800">
      <FaGamepad className="text-4xl text-green-500 mr-4" />
      <div className="flex flex-col">
        <span className="text-gray-400 text-sm">Wallet Balance</span>
        <span className="text-white text-2xl font-bold">{stats.wallet} <span className="text-green-500">Birr</span></span>
      </div>
    </div>
  </div>

    <div className="mt-8">
      <h4 className="text-xl font-semibold text-white mb-4">Recent Games</h4>
      
      <div className="overflow-x-auto rounded-lg border border-gray-800">
        <table className="min-w-full divide-y divide-gray-800">
          <thead className="bg-black">
            <tr>
              <th className="px-6 py-4 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Date</th>
              <th className="px-6 py-4 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Bet Amount</th>
              {/* <th className="px-6 py-4 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Players</th> */}
              <th className="px-6 py-4 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Status</th>
              {/* <th className="px-6 py-4 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Total Bet</th> */}
              <th className="px-6 py-4 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Profit</th>
            </tr>
          </thead>
          <tbody className="bg-black/30 divide-y divide-gray-800">
            {games.map(game => (
              <tr key={game.id} className="hover:bg-gray-800/50 transition-colors duration-200">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">{formatDate(game.date)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">{formatBetAmount(game)}</td>
                {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">{game.players}</td> */}
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                    game.status === 'completed' 
                      ? 'bg-green-900/50 text-green-400' 
                      : 'bg-yellow-900/50 text-yellow-400'
                  }`}>
                    {game.status}
                  </span>
                </td>
                {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300" >
                  {calculateTotalBetAmount(game).toLocaleString()} <span className="text-gray-500">Birr</span>
                </td> */}
                <td className="px-6 py-4 whitespace-nowrap text-sm text-green-400">
                  {game.profit ? Math.floor(game.profit) : Math.floor(calculateProfit(game))} <span className="text-gray-500">Birr</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Footer Section */}
      <div className="mt-4 flex justify-between items-center">
        <p className="text-gray-400 text-sm">
          Showing <span className="font-medium text-white">{games.length}</span> out of <span className="font-medium text-white">{totalGames}</span> games
        </p>
        
        <div className="flex gap-3">
          {page < totalPages && (
            <button 
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={loadMoreGames} 
              disabled={loading}
            >
              {loading ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Loading...
                </span>
              ) : 'Load More'}
            </button>
          )}
          <button className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200">
            Export CSV
          </button>
        </div>
      </div>
    </div>
  </div>
  );
}

export default AdminGames;