import { Container, Row, Col } from 'react-bootstrap';

function Card({ card }) {
  const isPower = false

  return (
    <div className={`max-w-md mx-auto ${isPower ? 'bg-yellow-400' : 'bg-gray-900'} 
      rounded-lg shadow-lg overflow-hidden transform transition-all duration-300 
      hover:shadow-xl my-0`}>
      
      {/* Card Header */}
      <div className="p-0">
        <h2 className={`text-2xl font-bold text-center ${isPower ? 'text-white' : 'text-yellow-400'}`}>
          Power Bingo - Cartella {card.paletteNumber}
        </h2>
      </div>

      {/* Bingo Grid */}
      <div className="grid grid-cols-5 gap-0.5 p-2 mb-4">
        {/* B Column */}
        <div className="flex flex-col">
          <div className={`${isPower ? 'bg-yellow-500' : 'bg-blue-600'} 
            text-white font-bold text-xl p-2 text-center rounded-t`}>
            B
          </div>
          {card.numbers.B.map((num, index) => (
            <div key={index} 
              className={`${isPower ? 'bg-yellow-400 text-white' : 'bg-white text-blue-600'} 
              border border-gray-300 p-2 text-center font-semibold hover:bg-opacity-90 
              transition-colors duration-200`}>
              {num}
            </div>
          ))}
        </div>

        {/* I Column */}
        <div className="flex flex-col">
          <div className={`${isPower ? 'bg-yellow-500' : 'bg-red-600'} 
            text-white font-bold text-xl p-2 text-center`}>
            I
          </div>
          {card.numbers.I.map((num, index) => (
            <div key={index} 
              className={`${isPower ? 'bg-yellow-400 text-white' : 'bg-white text-red-600'} 
              border border-gray-300 p-2 text-center font-semibold hover:bg-opacity-90 
              transition-colors duration-200`}>
              {num}
            </div>
          ))}
        </div>

        {/* N Column */}
        <div className="flex flex-col">
          <div className={`${isPower ? 'bg-yellow-500' : 'bg-yellow-600'} 
            text-white font-bold text-xl p-2 text-center`}>
            N
          </div>
          {card.numbers.N.map((num, index) => (
            <div key={index} 
              className={`${isPower ? 'bg-yellow-400 text-white' : 'bg-white text-yellow-600'} 
              border border-gray-300 p-2 text-center font-semibold hover:bg-opacity-90 
              transition-colors duration-200`}>
              {num}
            </div>
          ))}
        </div>

        {/* G Column */}
        <div className="flex flex-col">
          <div className={`${isPower ? 'bg-yellow-500' : 'bg-green-600'} 
            text-white font-bold text-xl p-2 text-center`}>
            G
          </div>
          {card.numbers.G.map((num, index) => (
            <div key={index} 
              className={`${isPower ? 'bg-yellow-400 text-white' : 'bg-white text-green-600'} 
              border border-gray-300 p-2 text-center font-semibold hover:bg-opacity-90 
              transition-colors duration-200`}>
              {num}
            </div>
          ))}
        </div>

        {/* O Column */}
        <div className="flex flex-col">
          <div className={`${isPower ? 'bg-yellow-500' : 'bg-gray-800'} 
            text-white font-bold text-xl p-2 text-center rounded-t`}>
            O
          </div>
          {card.numbers.O.map((num, index) => (
            <div key={index} 
              className={`${isPower ? 'bg-yellow-400 text-white' : 'bg-white text-gray-800'} 
              border border-gray-300 p-2 text-center font-semibold hover:bg-opacity-90 
              transition-colors duration-200`}>
              {num}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Card;