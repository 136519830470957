import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import AdminStats from './Stats';
import AllCards from './AllCards';
import AdminGames from './myGames';
import api from '../api';

const Report = () => {
  const [activeComponent, setActiveComponent] = useState('AdminGames');
  const navigate = useNavigate();
  const username = localStorage.getItem('username');
  
  const renderComponent = () => {
    switch (activeComponent) {
      case 'AdminStats':
        return <AdminStats />;
      case 'AllCards':
        return <AllCards />;
      case 'AdminGames':
        return <AdminGames />;
      default:
        return null;
    }
  };

  const handleNavClick = (componentName) => {
    setActiveComponent(componentName);
  };

  const handleBack = () => {
    navigate('/dashboard'); // Use navigate to go back to the dashboard
  };

  const displayName = localStorage.getItem('username');


  return (
  <div className="flex min-h-screen bg-gray-900">
    {/* Sidebar */}
    <div className="fixed inset-y-0 left-0 w-64 bg-gray-800 shadow-xl transition-all duration-300 m-4 rounded-xl">
      <div className="flex flex-col h-full p-6">
        {/* Header */}
        <div className="space-y-2">
          <h2 className="text-2xl font-bold text-white">Dashboard</h2>
          <p className="text-gray-400">Welcome back</p>
        </div>

        {/* Profile Section */}
        <div className="flex items-center mt-6 space-x-3">
          <div className="relative group">
            <div className="w-12 h-12 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 p-[2px]">
              <div className="flex items-center justify-center w-full h-full rounded-full bg-gray-800">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                  alt="Profile"
                  className="w-10 h-10 rounded-full"
                />
              </div>
            </div>
          </div>
          <h6 className="text-white font-medium">{displayName}</h6>
        </div>

        {/* Navigation */}
        <nav className="mt-8 flex-1">
          <ul className="space-y-1 p-0">
            <li>
              <button
                onClick={() => handleNavClick('AdminGames')}
                className="w-full flex items-center px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg transition-colors duration-200"
              >
                <svg className="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                Dashboard
              </button>
            </li>
            <li>
              <button
                onClick={() => handleNavClick('AllCards')}
                className="w-full flex items-center px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg transition-colors duration-200"
              >
                <svg className="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                </svg>
                Board
              </button>
            </li>
            <li>
              <button
                onClick={() => handleNavClick('AdminStats')}
                className="w-full flex items-center px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg transition-colors duration-200"
              >
                <svg className="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
                Report
              </button>
            </li>
          </ul>
        </nav>

        {/* Back Button */}
        <button
          onClick={handleBack}
          className="mt-auto w-full px-2 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200 flex items-center justify-center space-x-2"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          <span>Return to Game</span>
        </button>
      </div>
    </div>

    {/* Main Content */}
    <div className="ml-72 flex-1 p-8">
      {renderComponent()}
    </div>
  </div>
  );
};

export default Report;
