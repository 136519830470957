import React from 'react';

const LoadingOverlay = () => {
  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-[9999]">
      <div className="flex flex-col items-center gap-4">
        {/* Custom spinner */}
        <div className="w-16 h-16 border-4 border-t-gold border-r-gold border-b-transparent border-l-transparent rounded-full animate-spin">
        </div>
        
        {/* Loading text with animation */}
        <div className="text-gold text-xl font-bold tracking-wider">
          <span className="inline-block animate-bounce">P</span>
          <span className="inline-block animate-bounce" style={{ animationDelay: '0.1s' }}>o</span>
          <span className="inline-block animate-bounce" style={{ animationDelay: '0.2s' }}>w</span>
          <span className="inline-block animate-bounce" style={{ animationDelay: '0.3s' }}>e</span>
          <span className="inline-block animate-bounce" style={{ animationDelay: '0.4s' }}>r</span>
          <span className="inline-block animate-bounce" style={{ animationDelay: '0.5s' }}> </span>
          <span className="inline-block animate-bounce" style={{ animationDelay: '0.6s' }}>G</span>
          <span className="inline-block animate-bounce" style={{ animationDelay: '0.7s' }}>a</span>
          <span className="inline-block animate-bounce" style={{ animationDelay: '0.8s' }}>m</span>
          <span className="inline-block animate-bounce" style={{ animationDelay: '0.9s' }}>e</span>
          <span className="inline-block animate-bounce" style={{ animationDelay: '1.0s' }}>s</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingOverlay;